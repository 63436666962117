<template>
	<v-dialog v-model="dialog" fullscreen>
		<div class="full_dialog_header">
			<h2 class="tit">설치장소 선택</h2>
			<v-btn depressed icon plain class="btn_right" @click="close"
				><v-icon dark>
					mdi-window-close
				</v-icon></v-btn
			>
		</div>
		<div class="dialog_all_page pa-6" style="height: calc(100vh - 50px)">
			<div class="box_result2">
				<ul class="result">
					<template v-if="addrList && addrList.length > 0">
						<li v-for="(addr, i) in addrList" :key="i">
							<div class="v-btn" @click="selectAddress(addr, i)">
								<p>
									<span class="primary--text">
										{{ addr.TYPE === 'B' ? '기본주소지' : '최근주소지' }}
									</span>
								</p>
								<p>
									{{ addr.ADDR1 }}
									{{ addr.ADDR2 }}
								</p>
							</div>
						</li>
					</template>
					<template v-else>
						<li>최근 주소지가 없습니다.</li>
					</template>
				</ul>
			</div>
		</div>
	</v-dialog>
</template>
<script>
import filters from '@/mixins/filters'

export default {
	name: 'AddrList',
	mixins: [filters],
	props: {
		addrList: Array
	},
	data() {
		return {
			dialog: false,
			// switch: []
			custInfos: []
		}
	},
	methods: {
		mask(val) {
			return this.$options.filters.name(val)
		},
		open() {
			this.dialog = true
		},
		close() {
			this.dialog = false
		},
		async selectAddress(addr, index) {
			const ok = await this.$confirm({
				message: `선택한 주소를 다시 한번 확인해 주시기 바랍니다. 해당 주소로 입력을 진행하시겠습니까?<br/><br/>${addr.ADDR1} ${addr.ADDR2}`,
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) {
				this.$emit('select', {
					addr: addr,
					index: index,
					allSetAddr: false
				})
				this.close()
			} else {
				return
			}
		}
	}
}
</script>

<style lang="scss">
.v-input.inner_text.v-input--switch--inset .v-input--switch__track,
.v-input.inner_text.v-input--switch--inset .v-input--selection-controls__input {
	height: 32px;
	border-radius: 20px;
	width: 118px;
}

.v-input.inner_text.v-input--switch--inset.v-input--is-label-active
	.v-input--switch__track {
	background: #1b9edb !important;
	opacity: 1;
}

.v-input--selection-controls__ripple,
.v-application--is-ltr
	.v-input--switch--inset.v-input.inner_text.v-input--is-dirty
	.v-input--switch__thumb {
	width: 24px;
	height: 24px;
	transform: translate(87px, 0) !important;
	background: #fff;
}

.theme--ligh.v-input.inner_text.v-input--switch--inset
	.v-input--selection-controls__ripple,
.v-application--is-ltr .v-input--switch--inset .v-input--switch__thumb {
	width: 24px;
	height: 24px;
}

.v-application--is-ltr
	.v-input--switch--inset.inner_text
	.v-input--selection-controls__ripple {
	opacity: 0;
}

.v-input.inner_text.v-input--is-label-active.v-input--is-dirty.theme--light {
	position: relative;
	right: 0 !important;
}

.inner_text.v-input--selection-controls .v-radio > .v-label,
.inner_text.v-input--selection-controls .v-input__slot > .v-label {
	position: absolute !important;
	color: #fff;
	line-height: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	top: 3px;
	justify-content: center;
	font-size: 13px;
	right: 0 !important;
}

.inner_text.v-input--is-label-active.v-input--selection-controls
	.v-input__slot
	> .v-label {
	left: -41px !important;

	right: 0 !important;
}

body .v-input--selection-controls.v-input.inner_text {
	display: flex;
	position: absolute !important;
	right: 0;
	bottom: 16px;
	margin-bottom: 0 !important;
}

body .box_result2 .result li .v-btn {
	padding-bottom: 56px !important;
}
// .box_result2 .v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
// 	opacity: 0;
// }
</style>

<template>
	<div class="order_wrap fixed_wrap customer_area">
		<MStepper :step="2" />

		<div class="txt_box">
			<h1 class="tit_page">
				설치처 정보 선택
			</h1>
			<div class="txt_body1">
				주문한 제품을 설치할 장소를 선택해 주세요. <br />주문제품 수량이 많아
				여러곳에 설치하는 경우, 대표 설치처를 선택한 후 담당 판매인에게 별도
				문의 해주세요.
			</div>
		</div>
		<v-divider></v-divider>
		<v-form class="divider_input pf-0">
			<div class="customer_form_wrap mt-5">
				<h3 class="tit">대표 설치처</h3>
				<div class="wrap_input">
					<v-text-field
						outlined
						dense
						placeholder="휴대폰 번호를 입력해 주세요."
						inputmode="numeric"
						maxlength="11"
						minlength="10"
						v-mask="['#########', '##########', '###########']"
						v-model="tellNum"
						:rules="rules.phone"
					/>
				</div>
				<div class="wrap_input">
					<v-text-field
						outlined
						dense
						placeholder="자택번호를 입력해 주세요."
						inputmode="numeric"
						maxlength="20"
						v-mask="['#########', '##########', '###########']"
						v-model="setupTelNumber"
					/>
				</div>

				<div class="wrap_input add_data">
					<v-btn depressed outlined class="btn_row" @click="addrSearch">
						<span
							class="ico_search ft-black"
							:rules="rules.addr"
							v-if="addr.length > 1"
						>
							{{ addr }}</span
						>
						<span class="ico_search ft-blue" v-else> {{ emptyDddr }}</span>
					</v-btn>
					<div class="importantMessage">
						아파트명, 건물명을 필수로 입력하세요.
					</div>
				</div>

				<v-btn
					class="btn_secondary mb-2 mt-5"
					default
					large
					@click="addrSelect"
				>
					주소지 목록</v-btn
				>
			</div>
		</v-form>
		<div class="bottom_box flex_row">
			<!-- disabled -->
			<v-btn large class="mb-2 btn_grey" color="grey" @click="back">이전</v-btn>
			<!-- abled -->
			<v-btn large class="mb-2 btn_primary" @click="save" :disabled="!chk"
				>입력 완료</v-btn
			>
		</div>
		<AddrList
			ref="addrSelect"
			:addrList="addrList"
			@select="selectAddress($event)"
		/>
		<InstAddrSearch ref="addrSearch" />
	</div>
</template>

<script>
import { fetchAddrList } from '@/apis/order.inst.api'
import { custInfoSave, fetchCustSetup } from '@/apis/order.verify.api'
import AddrList from '@/views/verify/cust/AddrList'
import InstAddrSearch from '@/views/inst/InstAddrSearch'
import { required, minLength, phoneLength } from '@/utils/rules'
import { phoneNumber } from '@/utils/rules'

export default {
	name: 'SetupAdd',
	data() {
		return {
			rules: {
				name: [v => required(v), v => minLength(v, 2)],
				relationName: [v => required(v)],
				phone: [v => required(v), v => phoneLength(v, 11), v => phoneNumber(v)],
				addr: [v => required(v)]
				// setupTelNumber: [v => tellNumber(v)]
			},
			dialog: false,
			addrList: [],
			name: '',
			tellNum: '',
			setupTelNumber: '',
			addr: '',
			emptyDddr: '주소 입력',
			postCode: '',
			addr1: '',
			addr2: '',
			zipSeq: '',
			nameChk: false,
			relationNameChk: false,
			tellChk: false,
			addrChk: false,
			chk: false,
			setupInfo: {},
			webOrderNo: this.$store.getters['verify/mobOrderNo'],
			custInfo: this.$store.getters['verify/custInfo'],
			tempSetup: this.$store.getters['custom/tempSetup'],
			setupUserRelationItems: [
				{ code: '0000', text: '본인' },
				{ code: '0001', text: '가족' },
				{ code: '0004', text: '친인척' },
				{ code: '0005', text: '기타' }
			],
			setupUserRelationCode: '',
			relationName: '',
			userRelationTextName: ''
		}
	},
	components: { AddrList, InstAddrSearch },
	async mounted() {
		const setup = await fetchCustSetup(this.webOrderNo)
		const setupData = setup.resultObject.data
		if (setupData.TYPE === 'T') {
			this.name = setupData.setup.name
			this.relationName = setupData.setup.nickname ?? setupData.setup.name
			this.setupUserRelationCode = setupData.setup.cuRelation ?? '0000'
			this.userRelationTextName =
				this.setupUserRelationName(this.setupUserRelationCode) ?? '본인'
			this.tellNum = setupData.setup.telNumber
			this.setupTelNumber = setupData.setup.setupTelNumber
		} else {
			this.name = this.tempSetup.name ?? this.$store.getters['verify/custName']
			this.relationName =
				this.tempSetup.name ?? this.$store.getters['verify/custName']
			this.setupUserRelationCode = '0000'
			this.userRelationTextName = '본인'
			this.tellNum =
				this.tempSetup.tellNum ?? this.$store.getters['verify/custPhone']

			if (this.custInfo.ZIP_SEQ !== '00000000') {
				this.postCode = this.tempSetup.postCode ?? this.custInfo.POST_CODE1
				this.addr =
					this.tempSetup.addr ??
					`${this.custInfo.CITY1} ${this.custInfo.STREET}`
				this.addr1 = this.tempSetup.addr1 ?? this.custInfo.CITY1
				this.addr2 = this.tempSetup.addr2 ?? this.custInfo.STREET
				this.zipSeq = this.tempSetup.zipSeq ?? this.custInfo.ZIP_SEQ
				this.setupInfo.zipSeq = this.tempSetup.zipSeq ?? this.custInfo.ZIP_SEQ
				this.setupInfo.zonecode =
					this.tempSetup.postCode ?? this.custInfo.POST_CODE1
				this.setupInfo.zonecode = this.setupInfo.zonecode.replaceAll('-', '')
				this.setupInfo.roadAddress = this.tempSetup.addr1 ?? this.custInfo.CITY1
				this.setupInfo.detailAddress =
					this.tempSetup.addr2 ?? this.custInfo.STREET
			}
			this.$log('this.addr', this.addr)
			if (this.addr !== '') {
				const ok = await this.$confirm({
					message: `등록된 주소로 기본 설정됩니다. 해당 주소를 다시 한번 확인해 주시기 바랍니다. 해당 주소로 입력을 진행하시겠습니까?<br/><br/>${this.addr}`,
					okText: '확인',
					cancelText: '취소'
				})
				if (!ok) {
					this.addr = ''
				}
			}
		}

		const res = await fetchAddrList({
			kunnr: this.$store.state['verify'].customer.custNo
		})

		this.addrList = res.resultObject.data.T_SETUP_LIST.filter(
			x => x.ZIP_SEQ !== '00000000'
		)
	},
	methods: {
		addrSelect() {
			this.$refs.addrSelect.open()
		},
		setupUserRelationName(code) {
			let res = ''
			switch (code) {
				case '0000':
					res = '본인'
					break
				case '0001':
					res = '가족'
					break
				case '0004':
					res = '친인척'
					break
				case '0005':
					res = '기타'
					break
				default:
					break
			}
			return res
		},
		async addrSearch() {
			const addr = await this.$refs.addrSearch.open()
			this.setupInfo = addr
			this.addr = `${addr.roadAddress} ${addr.detailAddress}`
			this.emptyDddr = ''
			this.addr1 = addr.roadAddress
			this.addr2 = addr.detailAddress
			this.zipSeq = addr.zipSeq
		},
		selectAddress(obj) {
			this.setupInfo = obj
			this.addr = obj.addr.ADDR1 + obj.addr.ADDR2
			this.addr1 = obj.addr.ADDR1
			this.addr2 = obj.addr.ADDR2
			this.zipSeq = obj.addr.ZIP_SEQ
		},
		async save() {
			let param = {}
			param.name = this.name
			param.telNumber = this.tellNum
			param.setupTelNumber = this.setupTelNumber
			param.nickname = this.relationName
			param.cuRelation = this.setupUserRelationCode
			param.postCode = this.setupInfo.zonecode ?? this.setupInfo.addr.POST_CODE
			param.addr1 = this.setupInfo.roadAddress ?? this.setupInfo.addr.ADDR1
			param.addr2 = this.setupInfo.detailAddress ?? this.setupInfo.addr.ADDR2
			if (this.setupInfo?.zipSeq || this.setupInfo.addr?.ZIP_SEQ) {
				param.zipseq = this.setupInfo.zipSeq ?? this.setupInfo.addr.ZIP_SEQ
			}
			this.$log('param', param)

			let account = []

			account.push(this.$store.getters['custom/accountInfo'])
			account.push(this.$store.getters['custom/onceAccountInfo'])
			account = account.map(d => {
				return {
					payType: d.payType,
					payMthd: d.AF_PAY_MTHD,
					cardcomCd: d.AF_BANK_CD,
					cardcomNm: d.AF_BANK_CD_NM,
					cardNo: d.AF_CARD_NO,
					ownerNm: d.AF_OWNER_NM,
					ssnNo: d.AF_SSN_NO,
					payDd: d.payType === 'O' ? '' : d.AF_PAY_DD,
					expireYm: d.AF_EXPIRE_YM ?? '',
					mainPayment: true,
					katr2: d.KATR2 ?? '1',
					sapYn: d.SAP_YN
				}
			})
			const res = await custInfoSave(this.webOrderNo, {
				PAYMENT_LIST: account,
				SETUP: param
			})
			if (res.resultObject.data.TYPE === 'T') {
				this.$router.push({
					name: 'cust-paycomplete'
				})
			}
		},
		valid() {
			if (
				(this.relationNameChk || this.nameChk) &&
				this.tellChk &&
				this.addrChk
			) {
				this.chk = true
			} else {
				this.chk = false
			}
		},
		back() {
			const tempSetup = {
				name: this.name,
				tellNum: this.tellNum,
				setupTelNumber: this.setupTelNumber,
				addr: this.addr,
				addr1: this.addr1,
				addr2: this.addr2,
				postCode: this.postCode,
				zipSeq: this.zipSeq
			}
			this.$store.dispatch('custom/setTempSetup', tempSetup)
			this.$router.push({
				name: 'cust-payadd'
			})
		}
	},
	watch: {
		relationName(val) {
			if (val.length >= 1) {
				this.relationNameChk = true
			} else {
				this.relationNameChk = false
			}
			this.valid()
		},
		name(val) {
			if (val.length >= 2) {
				this.nameChk = true
			} else {
				this.nameChk = false
			}
			this.valid()
		},
		tellNum(val) {
			if (val.length >= 10) {
				this.tellChk = true
			} else {
				this.tellChk = false
			}
			this.valid()
		},

		addr(val) {
			if (val.trim() == '' || val.trim() == null || val.trim() == undefined) {
				this.addrChk = false
			} else {
				this.addrChk = true
			}
			this.valid()
		}
	}
}
</script>
